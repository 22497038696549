var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.addSendVisible
        ? _c(
            "Modal",
            {
              attrs: {
                size: "normal",
                closable: true,
                visible: _vm.addSendVisible,
                title: "添加抄送人",
                "ok-text": "确认",
              },
              on: { ok: _vm.handleSubmit, cancel: _vm.handleCancel },
            },
            [
              _c(
                "div",
                { staticClass: "operation" },
                [
                  _c("p", { staticClass: "operation-title first-title" }, [
                    _vm._v("选择抄送人"),
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { icon: "plus-circle" },
                      on: { click: _vm.selectEmployees },
                    },
                    [_vm._v(" 选择部门和员工 ")]
                  ),
                  _vm.form.copyToList.length
                    ? _c("UserEcho", {
                        staticClass: "user-echo",
                        attrs: { "user-list": _vm.form.copyToList },
                        on: { delUser: _vm.delUser },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "operation" },
                [
                  _c("p", { staticClass: "operation-title" }, [
                    _vm._v("抄送原因"),
                  ]),
                  _c("a-textarea", {
                    attrs: {
                      placeholder: "内容描述",
                      "auto-size": { minRows: 5, maxRows: 5 },
                      "max-length": 100,
                    },
                    model: {
                      value: _vm.form.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reason", $$v)
                      },
                      expression: "form.reason",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("PerModelSelect", {
        attrs: {
          "data-echo": _vm.form.copyToList,
          placeholder: "请输入部门、人员、工号",
        },
        on: { perModelConfirm: _vm.perModelConfirm },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }